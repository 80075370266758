/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Blog"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--80" name={"informace-2"} layout={"l13"}>
        </Column>


        <Column className="--center pb--80 pt--80" name={"qd4sm7p1be"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Blog"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"4d1ymue2m87"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/45af3e0765fa45b2be36aaba227c1f3a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/38719/45af3e0765fa45b2be36aaba227c1f3a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38719/45af3e0765fa45b2be36aaba227c1f3a_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box" content={"<a href=\"/ai-dobry-sluha-nebo-zly-pan\">AI: Dobrý sluha nebo zlý pán?</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Umělá inteligence (AI) představuje jednu z největších technologických inovací současnosti. S pokrokem je však spojené riziko."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/3931206c122e4cecbd86f3880edb8750_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/38719/3931206c122e4cecbd86f3880edb8750_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38719/3931206c122e4cecbd86f3880edb8750_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38719/3931206c122e4cecbd86f3880edb8750_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box" content={"<a href=\"/ai-v-kazdodennim-zivote-jak-uz-ted-vyuzivate-umelou-inteligenci-aniz-byste-to-vedeli\">AI v každodenním životě:&nbsp;Jak už teď využíváte umělou inteligenci, aniž byste to věděli\n</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"AI se může zdát jako novinka, ale existuje mnoho činností, kde umělou inteligenci využíváte a možná ani nevíte proč."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"78rxvfzzieo"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/37882cf592c84381885a9bafd4b87195_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38719/37882cf592c84381885a9bafd4b87195_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38719/37882cf592c84381885a9bafd4b87195_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38719/37882cf592c84381885a9bafd4b87195_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38719/37882cf592c84381885a9bafd4b87195_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"<a href=\"/ai-hrozba-demokracie\">AI jako hrozba demokracie</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Může být umělá inteligence skutečnou hrozbou pro demokratické režimy?"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38719/7632cdd4d8c54f2597cf8d1bad1ada7d_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"<a href=\"/jak-muze-umela-inteligence-pomoci-pri-predpovidani-a-zvladani-prirodnich-katastrof\">Jak může umělá inteligence pomoci při předpovídání a zvládání přírodních katastrof</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Dokáže umělá inteligence zlepšit schopnost vyhodnocovat potenciální přírodní katastrofy?"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38719/2c03b698540e426291180b0e0cfc94c5_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"<a href=\"/vyuziti-ai-pro-optimalizaci-dopravniho-toku\">Využití AI pro optimalizaci dopravního toku</a>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Dokáže umělá inteligence zdokonalit systém dopravní infrastruktury tak, aby lidem umožnila rychlejší a bezpečnější transport?"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax" style={{"backgroundColor":"var(--color-blend--95)","marginTop":0,"paddingBottom":0}} name={"kontakt"} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" style={{"maxWidth":1310}} anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"Pojďte s námi ovládnout technologie zítřka ještě dnes<br>"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Kontaktujte nás"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Skolainovace.cz</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">skolainovace.cz, s. r. o.<br>Email: info@skolainovace.cz<br>Telefon: 795 435 923<br>IČ:&nbsp;21803668<br>Malá Štěpánská 1930/7<br>120 00 Praha 2</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Zadejte text"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"/o_nas\" style=\"color: var(--white);\">O nás</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/nabidka-kurzu\" style=\"color: var(--white);\">Nabídka kurzů</a><a href=\"/blog\" style=\"color: var(--white);\"><br>Blog<br></a><a href=\"https://www.facebook.com/profile.php?id=61559873844823\" target=\"_blank\" style=\"color: var(--white);\">Facebook</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/zasady-zpracovani-osobnich-udaju\" style=\"color: var(--white);\">Zásady zpracování osobních údajů</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}